import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '150px' }}>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            <Link to="/" style={{ textDecoration: 'none', color: 'blue' }}>
                Go back to the homepage
            </Link>
        </div>
    );
};

export default ErrorPage;
