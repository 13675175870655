import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../hooks/useAuthContext'
import '../styles/Login.css';

const Login = () => {
    const { dispatch } = useAuthContext()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    const [serverError, setServerError] = useState('');

    const onSubmit = async (data) => {
        try {
            setServerError('');

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                setServerError(errorData.message || 'An error occurred.');
                return;
            }

            if (response.ok) {
                const responseData = await response.json();
                localStorage.setItem('user', JSON.stringify(responseData.user));

                dispatch({ type: 'LOGIN', payload: responseData.user })
            }
            reset();
        } catch (error) {
            console.error('Error during login:', error.message);
            setServerError('A network error occurred. Please try again.');
        }
    };

    return (
        <div className="login-container">
            <h1 className="login-title">Login</h1>
            <form onSubmit={handleSubmit(onSubmit)} className="login-form">
                {serverError && <p className="login-error">{serverError}</p>}
                <div className="login-inputGroup">
                    <label>Username</label>
                    <input
                        type="text"
                        {...register('username', {
                            required: 'Username is required.'
                        })}
                        className="login-input"
                    />
                    {errors.username && <p className="login-error">{errors.username.message}</p>}
                </div>

                <div className="login-inputGroup">
                    <label>Password</label>
                    <input
                        type="password"
                        {...register('password', {
                            required: 'Password is required.'
                        })}
                        className="login-input"
                    />
                    {errors.password && <p className="login-error">{errors.password.message}</p>}
                </div>

                <button type="submit" className="login-button">Login</button>
            </form>
        </div>
    );
};

export default Login;
