import React, { useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import NewLocationModal from '../components/NewLocationModal';
import { useAuthContext } from '../hooks/useAuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const fetchLocations = async ({ queryKey }) => {
    const merchantID = queryKey[1];
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/${merchantID}/locations`, {
        method: 'GET',
        credentials: 'include'
    });
    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to fetch locations: ${errorDetails.message}`);
    }
    return response.json();
};

const LocationsTable = ({ merchantID }) => {
    const [showModal, setShowModal] = useState(false);
    const queryClient = useQueryClient();
    const { user } = useAuthContext()

    const { data: locations, isLoading, isError } = useQuery({
        queryKey: ['locations', merchantID],
        queryFn: fetchLocations
    });

    const mutation = useMutation({
        mutationFn: async (newLocation) => {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/${merchantID}/newlocation`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newLocation),
            });
            if (!response.ok) {
                const errorDetails = await response.text();
                throw new Error(`Error creating location: ${errorDetails}`);
            }
            return response.json();
        },
        onMutate: async (newLocation) => {
            await queryClient.cancelQueries(['locations', merchantID]);
            const previousLocations = queryClient.getQueryData(['locations']);
            queryClient.setQueryData(['locations', merchantID], (old) => [...(old || []), { ...newLocation, id: 999999 }]);
            return { previousLocations };
        },
        onSuccess: (data) => {
            console.log('Location created successfully:', data);
            toast.success(`Location "${data.location.name}" has been successfully created!`);
        },
        onError: (error, newLocation, context) => {
            toast.error('An error occurred while creating the location. Please try again.');
            if (context?.previousLocations) {
                queryClient.setQueryData(['locations', merchantID], context.previousLocations);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(['locations', merchantID]);
        },
    });

    const columns = useMemo(() => [
        { headerName: 'Location ID', field: 'id' },
        { headerName: 'Name', field: 'name' },
        { headerName: 'Address', field: 'address' },
        { headerName: 'User Name', field: 'username' },
    ], []);

    const handleCreateLocation = async (newLocation) => {
        try {
            await mutation.mutate(newLocation);
        } catch (error) {
            console.error('Error during mutation call:', error);
            toast.error('An error occurred while creating the location.');
        }
        setShowModal(false);
    };

    return (
        <div className='locations'>
            {isLoading && <div>Loading...</div>}
            {isError && <div>Error loading locations.</div>}
            {!isLoading && !isError && (
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    {locations && locations.length > 0 ? (
                        <AgGridReact
                            rowData={locations}
                            columnDefs={columns}
                            pagination={true}
                            paginationPageSize={10}
                        />
                    ) : (
                        <div>No locations available.</div>
                    )}
                </div>
            )}
            <NewLocationModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                onSubmit={handleCreateLocation}
            />
            {user?.role === 'INTESA_ADMIN' && (
                <div className="new-offer-button" style={{ marginTop: '20px' }}>
                    <Button variant="primary" onClick={() => setShowModal(true)}>
                        New Location
                    </Button>
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default LocationsTable;
