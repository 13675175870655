import { useAuthContext } from '../hooks/useAuthContext';

export const useLogout = () => {
    const { dispatch } = useAuthContext();

    const logout = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/logout`, {
                method: 'POST',
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Failed to logout on the server');
            }
            localStorage.removeItem('user');

            dispatch({ type: 'LOGOUT' });
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return { logout };
};
