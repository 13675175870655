import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const OfferDetailsTable = () => {
    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        { field: 'productName', headerName: 'Product Name', sortable: true, filter: true },
        { field: 'price', headerName: 'Price', sortable: true, filter: true },
        { field: 'description', headerName: 'Description', sortable: true, filter: true },
        // Dodajte druge kolone prema podacima sa servera
    ]);

    const fetchVoucherDetails = async () => {
        const voucherProviderCode = 'VOUCHER_PROVIDER'; // Zamenite sa pravim kodom
        const url = `http://voucher-service-svc.marketplace.svc.cluster.local/vouchers/v1/content/provider/${voucherProviderCode}/product/details?marketplace=test_marketplace`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            // Pretpostavimo da je `data.products` niz koji sadrži detalje
            setRowData(data.products || []);
        } catch (error) {
            console.error('Error fetching voucher details:', error);
        }
    };

    useEffect(() => {
        fetchVoucherDetails();
    }, []);

    return (
        <div className="ag-theme-alpine coupon-grid" style={{ height: 400 }}>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={10}
            />
        </div>
    );
};

export default OfferDetailsTable;
