import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext';
import NavigationTabs from './components/NavigationsTabs';
import ListOfMerchants from './pages/ListOfMerchants';
import ListOfCardholders from './pages/ListOfCardholders';
import MerchantDetails from './pages/MerchantDetails';
import CardholderDetails from './pages/CardholderDetails';
import OfferDetails from './pages/OfferDetails';
import Login from './pages/Login'
import MerchantLocation from './pages/MerchantLocation';
import PrivateRoute from './components/PrivateRoute'
import ErrorPage from './pages/ErrorPage';

function App() {
  const { user, loading } = useAuthContext()

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <div className="pages">
          <NavigationTabs />
          <Routes>
            <Route
              path='/'
              element={user ? <Navigate to="/merchants" /> : <Navigate to="/login" />}
            />
            <Route
              path='/login'
              element={!user ? <Login /> : <Navigate to="/" />}
            />
            <Route
              path='/merchants'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_ADMIN']}>
                  <ListOfMerchants />
                </PrivateRoute>}
            />
            <Route
              path='/cardholders'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_ADMIN']}>
                  <ListOfCardholders />
                </PrivateRoute>
              }
            />
            <Route
              path='/merchants/:id'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_ADMIN', 'INTESA_MERCHANT']}>
                  <MerchantDetails />
                </PrivateRoute>
              }
            />
            <Route
              path='/cardholders/:id'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_ADMIN']}>
                  <CardholderDetails />
                </PrivateRoute>
              }
            />
            <Route
              path='/offers/:id'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_ADMIN', 'INTESA_MERCHANT']}>
                  <OfferDetails />
                </PrivateRoute>
              }
            />
            <Route
              path='/location'
              element={
                <PrivateRoute user={user} allowedRoles={['INTESA_LOCATION']}>
                  <MerchantLocation />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
