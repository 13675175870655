import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CardholderDetailsTable from '../components/CardholderDetailsTable';

import '../styles/CardholderDetails.css'


const CardholderDetails = () => {
    const { id } = useParams();
    const [cardholder, setCardholder] = useState(null);

    useEffect(() => {
        const fetchCardholderDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cardholders/${id}`, {
                    method: 'GET',
                    credentials: 'include'
                });
                console.log('Response:', response);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log('Data fetched:', data);
                setCardholder(data);
            } catch (error) {
                console.error('Failed to fetch cardholder details:', error);
            }
        };


        fetchCardholderDetails();
    }, [id]);

    if (!cardholder) return <div>Loading...</div>;

    return (
        <>
            <div className="cardholder-details">
                <div className="cardholder-info">
                    <h2>Cardholder Details</h2>
                    <div className="info-item">
                        <label>ID: </label>
                        <span>{cardholder.id}</span>
                    </div>
                    <div className="info-item">
                        <label>Segment: </label>
                        <span>{cardholder.segment}</span>
                    </div>
                    <div className="info-item">
                        <label>Email: </label>
                        <span>{cardholder.email}</span>
                    </div>
                    <div className="info-item">
                        <label>Subsegment: </label>
                        <span>{cardholder.subsegment}</span>
                    </div>
                </div>
            </div>
            <CardholderDetailsTable />
        </>
    );
};

export default CardholderDetails;
