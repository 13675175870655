import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const EditMerchantModal = ({ show, handleClose, onSubmit, merchantData }) => {
    const { register, handleSubmit, getValues, reset } = useForm();
    const [charCount, setCharCount] = useState({
        companyName: 0,
        shortDescription: 0,
        longDescription: 0,
    });

    useEffect(() => {
        if (merchantData) {
            reset({
                companyName: merchantData.title || '',
                category: '',
                serviceOrder: merchantData.service_order || '',
                shortDescription: merchantData.short_description || '',
                longDescription: merchantData.long_description || '',
                logo: merchantData.small_image_url || '',
                heroImage: merchantData.large_image_url || '',
                logoColorCode: merchantData.logo_color_code || '',
                active: merchantData.active || false
            });

            setCharCount({
                companyName: merchantData.title?.length || 0,
                shortDescription: merchantData.short_description?.length || 0,
                longDescription: merchantData.long_description?.length || 0,
            });
        }
    }, [merchantData, reset]);

    const handleInputChange = (field, value) => {
        setCharCount((prev) => ({
            ...prev,
            [field]: value.length,
        }));
    };

    const handleFormSubmit = () => {
        const updatedMerchant = getValues();
        const merchantWithId = { ...updatedMerchant, id: merchantData.id };
        onSubmit(merchantWithId);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Merchant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="companyName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter company name"
                                    {...register('companyName')}
                                    onChange={(e) => handleInputChange('companyName', e.target.value)}
                                />
                                <div className="text-muted">{charCount.companyName}/20 characters</div>
                            </Form.Group>

                            <Form.Group controlId="category" className="mt-3">
                                <Form.Label>Category</Form.Label>
                                <Form.Control
                                    as="select"
                                    {...register('category', { required: 'Category is required' })}
                                    defaultValue=""
                                >
                                    <option value="" disabled>
                                        Select a Category
                                    </option>
                                    <option value="Auto moto svet">Auto moto svet</option>
                                    <option value="Lepota i zdravlje">Lepota i zdravlje</option>
                                    <option value="Moda">Moda</option>
                                    <option value="Gorivo">Gorivo</option>
                                    <option value="Opremanje doma i elektronika">Opremanje doma i elektronika</option>
                                    <option value="Dečiji kutak">Dečiji kutak</option>
                                    <option value="Lifestyle">Lifestyle</option>
                                    <option value="Kućni ljubimci">Kućni ljubimci</option>
                                    <option value="Putovanja">Putovanja</option>
                                    <option value="Pretplate i digitalne usluge">Pretplate i digitalne usluge</option>
                                    <option value="Restorani i barovi">Restorani i barovi</option>
                                    <option value="Namirnice">Namirnice</option>
                                    <option value="Kladionice">Kladionice</option>
                                    <option value="Ostalo">Ostalo</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="serviceOrder" className="mt-3">
                                <Form.Label>Service Order</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter service order"
                                    {...register('serviceOrder')}
                                />
                            </Form.Group>

                            <Form.Group controlId="logoColorCode" className="mt-3">
                                <Form.Label>Logo Color Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter logo color code"
                                    {...register('logoColorCode')}
                                />
                            </Form.Group>

                            <Form.Group controlId="active" className="mt-3">
                                <Form.Label>Is Active</Form.Label>
                                <div>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Active"
                                        id="active-true"
                                        value="true"
                                        {...register('active', { required: true })}
                                        defaultChecked={merchantData?.active === true}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Inactive"
                                        id="active-false"
                                        value="false"
                                        {...register('active', { required: true })}
                                        defaultChecked={merchantData?.active === false}
                                    />
                                </div>
                            </Form.Group>

                        </Col>

                        <Col md={6}>
                            <Form.Group controlId="shortDescription">
                                <Form.Label>Short Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Enter short description"
                                    {...register('shortDescription')}
                                    onChange={(e) => handleInputChange('shortDescription', e.target.value)}
                                />
                                <div className="text-muted">{charCount.shortDescription}/200 characters</div>
                            </Form.Group>

                            <Form.Group controlId="longDescription" className="mt-3">
                                <Form.Label>Long Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter long description"
                                    {...register('longDescription')}
                                    onChange={(e) => handleInputChange('longDescription', e.target.value)}
                                />
                                <div className="text-muted">{charCount.longDescription}/2000 characters</div>
                            </Form.Group>

                            <Form.Group controlId="logo" className="mt-3">
                                <Form.Label>Logo URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter logo URL"
                                    {...register('logo')}
                                />
                            </Form.Group>

                            <Form.Group controlId="heroImage" className="mt-3">
                                <Form.Label>Hero Image URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter hero image URL"
                                    {...register('heroImage')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="text-end mt-4">
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditMerchantModal;
