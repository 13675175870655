import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import logo from '../assets/banca_intesa_galerija_popusta_logo.png';
import ValidationModal from '../components/ValidationModal';
import '../styles/MerchantLocation.css';

const MerchantLocation = () => {
    const { register, handleSubmit, formState: { errors }, watch } = useForm(); // Added `watch`
    const [loading, setLoading] = useState(false);
    const [modalData, setModalData] = useState(null);

    const voucherCode = watch('voucherCode');

    const onSubmit = async (data) => {
        setLoading(true);
        setModalData(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/voucher`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();

            if (response.ok) {
                setModalData({
                    status: responseData.status,
                    message: responseData.message,
                    image: responseData.image,
                    name: responseData.name
                });
            } else {
                setModalData({
                    status: responseData.status,
                    message: responseData.message,
                    activationTime: responseData.activationTime,
                    activationLocation: responseData.activationLocation,
                    image: responseData.image,
                    name: responseData.name
                });
            }
        } catch (error) {
            setModalData({
                status: 'error',
                message: 'Greška u komunikaciji sa serverom.',
            });
        } finally {
            setLoading(false);
        }
    };

    const isButtonEnabled = !!voucherCode && !loading;

    return (
        <div className="location-container">
            <img src={logo} alt="Banca Intesa Galerija Popusta" className="location-logo" />
            <form className="location-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="location-input-group">
                    <label htmlFor="voucherCode" className="location-label">
                        Unesite broj kupona:
                    </label>
                    <input
                        id="voucherCode"
                        placeholder="Broj kupona"
                        className={`location-input ${errors.voucherCode ? 'location-input-error' : ''}`}
                        {...register('voucherCode', { required: 'Broj kupona je obavezan' })}
                    />
                    {errors.voucherCode && (
                        <p className="location-error">{errors.voucherCode.message}</p>
                    )}
                </div>
                <button
                    type="submit"
                    className={isButtonEnabled ? 'location-button-enabled' : 'location-button-disabled'}
                    disabled={!isButtonEnabled}
                >
                    {loading ? 'Proveravam...' : 'Proveri'}
                </button>
            </form>

            {modalData && (
                <ValidationModal
                    show={modalData}
                    status={modalData.status}
                    message={modalData.message}
                    image={modalData.image}
                    name={modalData.name}
                    activationTime={modalData.activationTime}
                    activationLocation={modalData.activationLocation}
                    onClose={() => setModalData(null)}
                />
            )}
        </div>
    );
};

export default MerchantLocation;
