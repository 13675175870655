import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ user, allowedRoles, children }) => {
    const location = useLocation();
    if (!user) {
        return <Navigate to="/login" />;
    }

    if (user.role === 'INTESA_MERCHANT') {
        const expectedPath = `/merchants/${user.service_id || 1}`;
        if (location.pathname !== expectedPath) {
            return <Navigate to={expectedPath} replace />;
        }
    }

    if (user.role === 'INTESA_LOCATION') {
        const expectedPath = `/location`;
        if (location.pathname !== expectedPath) {
            return <Navigate to={expectedPath} replace />;
        }
    }

    if (!allowedRoles.includes(user.role)) {
        return <Navigate to={`/merchants/${user.service_id || 1}`} />;
    }

    return children;
};

export default PrivateRoute;
