import React from 'react';
import { Button } from 'react-bootstrap';
import '../styles/ValidationModal.css';

const ValidationModal = ({ show, status, message, image, name, activationTime, activationLocation, onClose }) => {

    return (
        <div className={`validation-modal ${show ? 'show' : ''}`} role="dialog">
            <div className="modal-content">
                <div className="text-center">
                    <div
                        className={`modal-icon ${status === 'ACTIVE' ? 'active' : 'inactive'}`}
                        style={{
                            fontSize: '48px',
                            marginBottom: '10px',
                            color: status === 'ACTIVE' ? 'green' : 'red',
                        }}
                    >
                        {status === 'ACTIVE' ? '✔' : '╳'}
                    </div>
                    <h4>{message}</h4>
                    {activationTime && (
                        <div
                            style={{
                                marginTop: '10px',
                                fontSize: '14px',
                                color: 'gray',
                                textAlign: 'center',
                            }}
                        >
                            <p style={{ marginBottom: '5px' }}>
                                <strong>Vreme aktivacije:</strong> {new Date(activationTime).toLocaleString('sr-RS', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                    hour12: false
                                })}
                            </p>
                        </div>
                    )}

                    {activationLocation && (
                        <div
                            style={{
                                marginTop: '5px',
                                fontSize: '14px',
                                color: 'gray',
                                textAlign: 'center',
                            }}
                        >
                            <p style={{ marginBottom: '5px' }}>
                                <strong>Lokacija:</strong> {activationLocation}
                            </p>
                        </div>
                    )}

                    {image && (
                        <div
                            className="image-container"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '20px',
                                padding: '15px',
                                borderRadius: '10px',
                                background: 'white',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <img
                                src={image}
                                alt="Product"
                                style={{
                                    width: '80px',
                                    height: '80px',
                                    borderRadius: '5px',
                                    objectFit: 'cover',
                                }}
                            />
                            <div
                                style={{
                                    marginLeft: '10px',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            >
                                {name}
                            </div>
                        </div>
                    )}
                </div>

                <div className="d-flex justify-content-center mt-4">
                    <Button
                        className="validator-btn"
                        onClick={onClose}
                    >
                        Nazad
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ValidationModal;

